$tp--laptop--narrow-breakpoint: 1440px;
$tp--tablet-landscape-breakpoint: 1024px;
$tp--tablet-portrait-breakpoint: 768px;
$tp--mobile-portrait-wide-breakpoint: 600px;
$tp--mobile-portrait-narrow-breakpoint: 400px;

$tp--primary-background-color: #191a48;
$tp--background-color: #E9EEF5;
$tp--content-background-color: #fff;

$tp--text-color: $tp--primary-background-color;

$tp--header--content--height: 40px;
$tp--header--background-color: #fff;

$tp--sidebar--max-width: 240px;
$tp--sidebar--nav-current-background-color: rgba(69, 90, 233, 0.3);
