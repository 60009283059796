$tp--login-height: 480px;
$tp--login-header-offset: 60px;
$tp--footer-height: 60px;

.tp--login {
  background-color: $tp--primary-background-color;
  width: 100%;
  height: 100%;
  position: absolute;
  min-height: calc(#{$tp--login-height} + (#{$tp--login-header-offset} + 10px) * 2);

  .pf-c-form__helper-text {
    color: red;
    margin-top: 10px;
  }

  &__wrapper {
    background-color: $tp--content-background-color;
    position: absolute;
    max-width: 90%;
    width: 400px;
    height: $tp--login-height;
    max-height: 90%;
    padding: 48px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .pf-m-lg {
      &.pf-c-empty-state {
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        height: calc(100% - 100px);
        background-color: rgba(255, 255, 255, 0.8);
      }

      &.pf-c-title {
        visibility: hidden;
      }
    }
  }

  &__header {
    background-color: $tp--primary-background-color;
    position: absolute;
    left: 0;
    top: -#{$tp--login-header-offset};
    width: 100%;
    height: 40px;
    text-align: center;
  }

  &__heading {
    h1 {
      font-size: 22px;
      line-height: 24px;
      font-weight: 600;
      color: #151515;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #ced5e1;
    height: 60px;

    > a {
      width: 180px;
      margin: 0 auto;
      display: block;
      margin-top: 16px;
      text-align: center;
    }
  }

  &.tp--forgotten-password {
    .tp--login__wrapper {
      height: 340px;

      @media screen and (max-width: 480px) {
        padding: 48px 24px;
      }

      .pf-c-button.pf-m-primary {
        padding-left: 45px;
        padding-right: 45px;
      }
    }

    .tp--login__heading {
      height: 50px;
      margin-bottom: 20px;
    }

    .tp--forgotten-password__result {
      position: relative;
      text-align: center;
      top: -20px;

      &__icon {
        height: 160px;
        position: relative;
        svg {
          width: 60px;
          height: 60px;
          color: green;

          &.tp--error {
            color: #e14c4c;
          }
        }

        h2 {
          font-size: 18px;
          line-height: 32px;
          margin-top: 10px;
        }
      }
    }

    &.tp--result {
      h1 {
        text-align: center;
      }
    }
  }
}
