#import-page {
  .tp--page__header {
    .pf-c-form {
      margin-top: 28px;
    }

    .pf-l-toolbar__group {
      width: 280px;

      @media screen and (max-width: 680px) {
        &:first-child {
          margin-bottom: 20px;
        }
      }

      .pf-c-form__label {
        width: 180px;
        line-height: 24px;
        padding-bottom: 0;
        vertical-align: bottom;

        .pf-c-form__label-text{
          font-weight: normal;
          font-size: 16px;
        }
      }

      .pf-c-switch {
        margin-left: 20px;
      }
    }
  }

  .tp--import {
    &__wrapper {
      background-color: white;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
      padding: 46px 66px 40px 34px;
    }

    &__upload-box {
      position: relative;
      height: 200px;
      border: 3px dashed #777e84;
      border-image-source: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAKUlEQVR42mNgYGD4jwUTC7DqpZmBlIIhZOAoGAWjgJ5ZZgiWNoOzxAYAZ/s/wRzWMgkAAAAASUVORK5CYII=");
      border-image-slice: 1;
      border-image-repeat: round;
      margin-bottom: 30px;

      &.tp--drag-over {
        background-color: #e9eef5;
      }
    }

    &__input {
      opacity: 0;
    }

    &__icon {
      position: absolute;
      top: calc(50% - 46px);
      left: calc(50% - 26px);
      width: 52px;
      height: 52px;
      display: block;
      cursor: pointer;

      > svg {
        color: rgb(146, 212, 0);
        width: 100%;
        height: 100%;

        &.tp--check-icon {
          width: 70%;
          height: 70%;
          top: 15%;
          left: 15%;
          position: relative;
        }
      }
    }

    &__text {
      text-align: center;
      position: absolute;
      top: calc(50% + 6px);
      left: 0;
      width: 100%;
      font-size: 20px;
      color: #777e84;
      line-height: 32px;

      label {
        font-weight: bold;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__footer {
      margin-top: 45px;

      button {
        height: 36px;
        padding-left: 32px;
        padding-right: 32px;
        width: 148px;
        overflow: hidden;

        .pf-c-spinner {
          --pf-c-spinner--diameter: 20px;
          --pf-c-spinner--Width: 20px;
          --pf-c-spinner--Height: 20px;
          --pf-c-spinner__clipper--Width: 20px;
          --pf-c-spinner__clipper--Height: 20px;
        }
      }
    }
  }
  .tp--bold {
    font-weight: 800;
  }
}
