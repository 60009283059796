#detail-page {
  .tp--page__header {
    padding-bottom: 30px;

    .pf-c-button {
      position: absolute;
      left: 10px;
      padding: 0 16px;
      cursor: pointer;

      @media screen and (max-width: #{$tp--laptop--narrow-breakpoint}) {
        left: 0;
      }

      @media screen and (max-width: #{$tp--mobile-portrait-wide-breakpoint}) {
        position: static;
        float: left;
      }
    }

    section {
      overflow: hidden;
    }

    .tp--detail {
      &__member-field {
        width: 340px;
        float: left;
        height: 50px;
        margin-top: 30px;
        margin-right: 30px;
        border-bottom: 1px solid #9ea7b3;

        &:nth-child(3),
        &:nth-child(4) {
          width: 180px;
        }

        @media screen and (max-width: #{$tp--laptop--narrow-breakpoint}) {
          margin-right: 20px;
        }


        @media screen and (max-width: #{$tp--tablet-landscape-breakpoint}) {
          margin-right: 0;
          margin-top: 20px;

          &:nth-child(3){
            clear: left;
          }

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            margin-right: 20px;
          }
        }

        @media screen and (max-width: #{$tp--tablet-portrait-breakpoint}) {
          width: 90% !important;
          float: none;
          margin-left: 5%;
          margin-right: 5%;
        }

        &__label {
          font-size: 13px;
          color: #777e84;
          line-height: 18px;
          font-weight: bold;
        }

        &__value {
          font-size: 14px;
          line-height: 24px;
          padding: 4px 8px;
          white-space: nowrap;
        }
      }
    }
  }

  .tp--detail__profile {
    background-color: white;
    padding: 20px 40px;
    margin-bottom: 20px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

    @media screen and (max-width: #{$tp--tablet-landscape-breakpoint}) {
      padding: 20px;
    }

    h2 {
      font-size: 24px;
      font-weight: 800;
      line-height: 1;
    }

    &__block {
      width: 47%;
      max-width: 800px;
      float: left;
      margin: 0 0.5%;
      margin-top: 10px;

      &:first-of-type {
        margin-right: 4.5%;
      }

      @media screen and (max-width: #{$tp--tablet-portrait-breakpoint}) {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
      }

      h3 {
        font-size: 14px;
        line-height: 32px;
        text-transform: uppercase;
        font-weight: 800;
        margin-top: 10px;
        padding-left: 8px;
      }

      &__member-field {
        border-top: 1px solid #b3b3b3;
        line-height: 32px;
        font-size: 14px;
        padding: 2px 8px;

        &__label {
          display: block;
          width: 50%;
          color: #777e84;
          float: left;
          height: 100%;

          @media screen and (max-width: #{$tp--tablet-landscape-breakpoint}) {
            width: 40%;
          }
        }

        &__value {
          display: inline-block;
          width: 50%;
          word-break: break-all;

          @media screen and (max-width: #{$tp--tablet-landscape-breakpoint}) {
            width: 60%;
          }
        }
      }
    }
  }

  /* special handling for interaction with sidebar */
  .tp--sidebar-opened { 

    @media screen and (max-width: #{$tp--tablet-landscape-breakpoint}) {
      .tp--detail__profile__block {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}
