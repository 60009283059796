#users-manager {
  .tp--page__header {
    padding-bottom: 10px;

    .tp--filter-buttons {
      justify-content: space-between;

      button {
        padding-left: 45px;
        padding-right: 45px;
        height: 36px;
        overflow: hidden;

        .pf-c-spinner {
          --pf-c-spinner--diameter: 20px;
          --pf-c-spinner--Width: 20px;
          --pf-c-spinner--Height: 20px;
          --pf-c-spinner__clipper--Width: 20px;
          --pf-c-spinner__clipper--Height: 20px;
        }
      }
    }
  }

  .tp--page__table-wrapper {
    min-height: 500px;
  }

  .pf-c-table {
    max-width: 1800px;

    td[data-label="Status"] {
      text-align: left;
    }
  }
}

@media screen and (min-width: 1024px) {
  .tp--add-user {
    &.pf-c-modal-box.pf-m-lg {
      --pf-c-modal-box--MaxWidth: 60rem;
    }
    .pf-c-modal-box__body {
      min-height: 360px;
    }
  }
}

.tp--confirm-delete {
  .tp--user-name {
    font-weight: bold;
  }
}
