#root {
  width: 100%;
  height: 100%;
  --pf-global--BackgroundColor--100: #191a48;
}

body {
  --pf-global--FontFamily--sans-serif: "Nunito Sans", sans-serif;
  --pf-global--FontFamily--heading--sans-serif: "Nunito Sans", sans-serif;
}

.pf-c-page {
  .pf-c-button.pf-m-primary {
    background-color: #455ae9;
  }

  .pf-c-button.pf-m-secondary,
  .pf-c-button.pf-m-link {
    color: #455ae9;
  }

  .pf-c-button.pf-m-secondary::after {
    --pf-c-button--BorderColor: #455ae9;
  }

  .pf-c-button:disabled,
  .pf-c-button.pf-m-disabled {
    background-color: var(--pf-c-button--disabled--BackgroundColor);
  }

  .pf-c-table__sort.pf-m-selected .pf-c-button,
  .pf-c-table__sort.pf-m-selected .pf-c-table__sort-indicator {
    color: #455ae9;
  }

  .pf-c-form-control.pf-m-focus,
  .pf-c-form-control:focus {
    --pf-c-form-control--BorderBottomColor: #455ae9;
    outline-color: #455ae9;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background-color: #455ae9;
  }
}

.tp--clearfix {
  float: none;
  clear: both;
  display: block;
  height: 0;
}

.pf-c-alert.pf-m-inline.pf-m-danger {
  text-align: left;
}

.pf-c-alert-group.pf-m-toast{

  .pf-c-alert.pf-m-inline{
    box-shadow: var(--pf-global--BoxShadow--lg);
  }
}

.pf-c-table__action .pf-c-dropdown__menu-item {
  cursor: pointer;
}

.pf-c-switch__toggle-icon svg{
  margin-top: 4px;
}
