.tp--page__header {
  .tp--filters {
    margin-top: 28px;

    .pf-l-toolbar__group {
      margin-bottom: 20px;
    }

    .pf-c-form__group {
      width: 300px;
      display: block;

      @media screen and (max-width: #{$tp--laptop--narrow-breakpoint}) {
        width: 250px;
      }

      @media screen and (max-width: 650px) {
        width: 80vw;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    &__last-row {
      .pf-l-toolbar__group {
        margin-bottom: 0;
      }
    }
  }
}

.pf-c-empty-state {
  margin-top: 100px;
  margin: 0 auto;
}

.tp--page {
  &__table-wrapper {
    min-height: 780px;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  &__table-header {
    margin-bottom: 20px;
    margin-left: 33px;
    margin-right: 40px;
    overflow: hidden;

    h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 800;
      color: $tp--primary-background-color;
      float: left;
    }
  }
}

.pf-c-table {
  thead.tp--table__header > tr {
    border-bottom: 0 none;
  }

  tr > * {
    font-size: 14px;
  }

  td[data-label="Status"] {
    text-align: center;

    @media screen and (max-width: 1200px) {
      text-align: left;
    }

    > span {
      display: inline-block;
      position: relative;
      z-index: 2;
    }
  }

  th {
    .pf-c-table__sort-indicator {
      margin-left: 5px;
    }
  }
}

.tp--table--pagination {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  height: 50px;
  padding-right: 15px;
  z-index: 20;

  .pf-c-form-control {
    width: 60px;
  }
}

.pf-c-alert.pf-m-inline.pf-m-danger {
  text-align: left;
}
