header.pf-c-page__header {
  background-color: $tp--header--background-color;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  .pf-c-page__header-brand-toggle {
    .pf-c-button.pf-m-plain {
      color: $tp--text-color;
    }
  }

  .pf-c-page__header-brand-link .pf-c-brand {
    max-height: $tp--header--content--height;
  }

  .pf-c-dropdown__toggle.pf-m-plain {
    padding: 0;
    height: $tp--header--content--height;
    color: $tp--text-color;
    margin-right: 20px;

    .tp--header__user-name {
      line-height: 24px;
      margin-right: 7px;

      @media screen and (max-width: #{$tp--mobile-portrait-narrow-breakpoint}) {
        display: none;
      }
    }
    @media screen and (max-width: #{$tp--mobile-portrait-wide-breakpoint}) {
      margin-right: 0;
    }
  }

  .pf-c-page__header-tools {
    .tp--header__avatar-icon {
      @media screen and (max-width: #{$tp--mobile-portrait-wide-breakpoint}) {
        display: none;
      }
    }
  }
}

.pf-c-page__sidebar.pf-m-dark {
  background-color: $tp--primary-background-color;

  &.pf-m-expanded {
    max-width: $tp--sidebar--max-width;
  }
  &.pf-m-collapsed {
    max-width: 80px;

    .tp--sidebar__nav-item__label,
    .tp--sidebar__copyright {
      display: none;
    }
  }

  .pf-c-nav {
    width: 100%;

    &__simple-list {
      .pf-c-nav__link {
        height: 60px;
        line-height: 44px; /* without padding */
        border-top: 1px solid rgba(72, 73, 107, 0.4);
        border-left: 5px solid transparent;
        border-bottom: 1px solid #12122b;
        white-space: nowrap;

        > svg {
          fill: #777e84;
        }

        &:hover,
        &:focus {
          border-left: 5px solid #fa8b39;
          background-color: transparent;
          font-weight: 600;

          > svg {
            fill: white;
          }
        }

        &.pf-m-current {
          background-color: $tp--sidebar--nav-current-background-color;
          border-left: 5px solid #fa8b39;
          font-weight: 600;

          > svg {
            fill: white;
          }
        }
      }
    }

    .tp--sidebar__nav-item {
      &__label {
        vertical-align: top;
      }

      &__icon {
        width: 18px;
        height: 44px;
        margin-right: 13px;
        line-height: 44px;
        margin-left: 6px;

        &.tp--icon__admin {
          width: 22px;
          margin-left: 4px;
        }
      }
    }
  }

  .tp--sidebar__copyright {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #777e84;
    white-space: nowrap;

    &__name {
      color: white;
    }
  }
}

section.pf-c-page__main-breadcrumb {
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  border-bottom: 1px solid #eaeaea;
  background-color: #fafbfd;
}

.pf-c-page__main-section.pf-m-light {
  background-color: $tp--background-color;
  padding: 0;

  .pf-c-page__main-section {
    background-color: $tp--background-color;
    padding: 39px 45px 24px 50px;

    @media screen and (max-width: #{$tp--laptop--narrow-breakpoint}) {
      padding-left: 30px;
      padding-right: 50px;
    }

    @media screen and (max-width: #{$tp--tablet-landscape-breakpoint}) {
      /* restore library paddings */
      padding: var(--pf-c-page__main-section--PaddingTop) var(--pf-c-page__main-section--PaddingRight)
        var(--pf-c-page__main-section--PaddingBottom) var(--pf-c-page__main-section--PaddingLeft);
    }

    &.tp--page__header {
      background-color: transparent;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #e9eef5;
      padding: 34px 60px;
      position: relative;

      h1 {
        font-size: 30px;
        line-height: 24px;
        font-weight: 800;
        color: $tp--primary-background-color;
      }

      @media screen and (max-width: #{$tp--laptop--narrow-breakpoint}) {
        padding: 28px 40px;
      }

      @media screen and (max-width: #{$tp--mobile-portrait-wide-breakpoint}) {
        padding: 20px 30px;

        h1 {
          font-size: 26px;
        }
      }
      @media screen and (max-width: #{$tp--mobile-portrait-narrow-breakpoint}) {
        padding: 20px 20px;
      }
    }

    .tp--page__background {
      background-color: $tp--content-background-color;
      position: relative;
    }
  }
}

.pf-c-table {
  background-color: $tp--content-background-color;
}

.tp--go-home-link {
  margin-top: 30px;
}
